<!--  -->
<template>
  <div class="">
    <div class="sticky_header_container">
      <header class="header">
        <div class="visible-xs">
          <div class="container">
            <div class="navbar-header">
              <router-link to="/">
                <span class="navbar-brand"
                  ><img
                    :src="$rootUrl + '/home/img/headLogo.png'"
                    alt="Adagene"
                /></span>
              </router-link>
              <img
                :src="$rootUrl + '/home/xs-ico-menu.svg'"
                alt="Mobile Menu"
                data-toggle="collapse"
                data-target=".mobile-topnav-collapse"
                class="navbar-toggle"
              />
              <span class="sr-only">Toggle navigation</span>
            </div>
          </div>
        </div>
        <div class="downlist">
          <div class="header_container" id="topmenulist">
            <div class="container">
              <div class="row">
                <div class="col-sm-10 col-sm-offset-1">
                  <div class="row">
                    <div class="col-sm-6">
                      <router-link to="/">
                        <span class="navbar-brand"
                          ><img
                            :src="$rootUrl + 'home/img/headLogo.png'"
                            alt="Adagene"
                        /></span>
                      </router-link>
                    </div>
                    <div class="col-sm-6"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-10 col-sm-offset-1 toplist">
                  <div class="topnav_container">
                    <nav class="navbar navbar-default" role="navigation">
                      <ul
                        data-v-1f5ec8f0=""
                        id="menu-topnav"
                        class="nav navbar-nav"
                      >
                        <li
                          data-v-1f5ec8f0=""
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page
                            menu-item-has-children
                            menu-item-35
                            dropdown
                            dropLi
                          "
                          style="text-align: center"
                        >
                          <a data-v-1f5ec8f0="" :href="$rootUrl" class=""
                            >首页</a
                          >
                        </li>
                        <li
                          data-v-1f5ec8f0=""
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page
                            menu-item-has-children
                            menu-item-35
                            dropdown
                            dropLi
                          "
                          style="text-align: center"
                        >
                          <a
                            data-v-1f5ec8f0=""
                            href="javaScript:"
                            aria-haspopup="true"
                            class="dropdown-toggle dropChlid"
                            >盈喜集团
                            <span data-v-1f5ec8f0="" class="caret"></span
                          ></a>
                          <ul
                            data-v-1f5ec8f0=""
                            role="menu"
                            class="dropdown-menu dropList"
                            style="display: none"
                          >
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'synopsis'"
                                class=""
                              >
                                集团简介</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'administration'"
                                class=""
                              >
                                管理架构</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'operate'"
                                class=""
                              >
                                运营架构</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'pattern'"
                                class=""
                              >
                                集团模式</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'culture'"
                                class=""
                              >
                                集团文化</a
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          data-v-1f5ec8f0=""
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page
                            menu-item-has-children
                            menu-item-35
                            dropdown
                            dropLi
                          "
                          style="text-align: center"
                        >
                          <a
                            data-v-1f5ec8f0=""
                            href="javaScript:"
                            aria-haspopup="true"
                            class="dropdown-toggle dropChlid"
                            >业务介绍
                            <span data-v-1f5ec8f0="" class="caret"></span
                          ></a>
                          <ul
                            data-v-1f5ec8f0=""
                            role="menu"
                            class="dropdown-menu dropList"
                            style="display: none"
                          >
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'spin'"
                                class=""
                              >
                                纺织</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'logistics'"
                                class=""
                              >
                                物流</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'antiepidemic'"
                                class=""
                              >
                                防疫</a
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          data-v-1f5ec8f0=""
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page
                            menu-item-has-children
                            menu-item-35
                            dropdown
                            dropLi
                          "
                          style="text-align: center"
                        >
                          <a
                            data-v-1f5ec8f0=""
                            href="javaScript:"
                            aria-haspopup="true"
                            class="dropdown-toggle dropChlid"
                            >投资者关系
                            <span data-v-1f5ec8f0="" class="caret"></span
                          ></a>
                          <ul
                            data-v-1f5ec8f0=""
                            role="menu"
                            class="dropdown-menu dropList"
                            style="display: none"
                          >
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'directors'"
                                class=""
                              >
                                董事会</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'boardStructure'"
                                class=""
                              >
                                董事会结构</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'government'"
                                class=""
                              >
                                公司治理文件</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'regular'"
                                class=""
                              >
                                定期报告</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'quarter'"
                                class=""
                              >
                                季报</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'year'"
                                class=""
                              >
                                年报</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'demonstration'"
                                class=""
                              >
                                投资者演示文稿</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                href="https://www.tradingview.com/symbols/NASDAQ-ATXG/"
                                target="view_window"
                              >
                                股价图</a
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          data-v-1f5ec8f0=""
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page
                            menu-item-has-children
                            menu-item-35
                            dropdown
                            dropLi
                          "
                          style="text-align: center"
                        >
                          <a
                            data-v-1f5ec8f0=""
                            href="javaScript:"
                            aria-haspopup="true"
                            class="dropdown-toggle dropChlid"
                            >旗下子公司
                            <span data-v-1f5ec8f0="" class="caret"></span
                          ></a>
                          <ul
                            data-v-1f5ec8f0=""
                            role="menu"
                            class="dropdown-menu dropList"
                            style="display: none"
                          >
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'daying'"
                                class=""
                              >
                                东莞市泓翔商业有限公司</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'huapengfa'"
                                class=""
                              >
                                深圳市盈喜鹏发物流有限公司</a>
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'xinkuaijie'"
                                class=""
                              >
                                深圳市新快捷运输有限公司</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'daitou'"
                                class=""
                              >
                                汕头市佰伊服饰有限公司</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'hengsheng'"
                                class=""
                              >
                                东莞市恒圣威服装有限公司</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'yushang'"
                                class=""
                              >
                                东莞誉尚服饰有限公司</a
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          data-v-1f5ec8f0=""
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page
                            menu-item-has-children
                            menu-item-35
                            dropdown
                            dropLi
                          "
                          style="text-align: center"
                        >
                          <a
                            data-v-1f5ec8f0=""
                            href="javaScript:"
                            aria-haspopup="true"
                            class="dropdown-toggle dropChlid"
                            >新闻动态
                            <span data-v-1f5ec8f0="" class="caret"></span
                          ></a>
                          <ul
                            data-v-1f5ec8f0=""
                            role="menu"
                            class="dropdown-menu dropList"
                            style="display: none"
                          >
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <!-- <router-link :to="'/newlist/1/qyxw/企业新闻'"
                                >企业新闻</router-link
                              > -->
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'qyxw'"
                                class=""
                              >
                                企业新闻</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'hydt'"
                                class=""
                              >
                                行业动态</a
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          data-v-1f5ec8f0=""
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page
                            menu-item-has-children
                            menu-item-35
                            dropdown
                            dropLi
                          "
                          style="text-align: center"
                        >
                          <a
                            data-v-1f5ec8f0=""
                            href="javaScript:"
                            aria-haspopup="true"
                            class="dropdown-toggle dropChlid"
                            >联系我们
                            <span data-v-1f5ec8f0="" class="caret"></span
                          ></a>
                          <ul
                            data-v-1f5ec8f0=""
                            role="menu"
                            class="dropdown-menu dropList"
                            style="display: none"
                          >
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'address'"
                                class=""
                              >
                                盈喜地址</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'recruit'"
                                class=""
                              >
                                盈喜招聘</a
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          data-v-1f5ec8f0=""
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page
                            menu-item-has-children
                            menu-item-35
                            dropdown
                            dropLi
                          "
                          style="text-align: center"
                        >
                          <a
                            data-v-1f5ec8f0=""
                            href="javaScript:"
                            aria-haspopup="true"
                            class="dropdown-toggle dropChlid"
                            >Investor Relations
                            <span data-v-1f5ec8f0="" class="caret"></span
                          ></a>
                          <ul
                            data-v-1f5ec8f0=""
                            role="menu"
                            class="dropdown-menu dropList"
                            style="display: none"
                          >
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'corporate_profile'"
                                class=""
                              >
                                Corporate Profile</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'directors_en'"
                                class=""
                              >
                                Management and Board of Directors
                              </a>
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'composition'"
                                class=""
                              >
                                Board Committee Composition</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'government'"
                                class=""
                              >
                                Corporate Governance</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'eng_news'"
                                class=""
                              >
                                Press Releases</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'regular'"
                                class=""
                              >
                                Periodic Reports</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'demonstration'"
                                class=""
                              >
                                Investor Presentation</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                href="https://www.tradingview.com/symbols/NASDAQ-ATXG/"
                                target="view_window"
                              >
                                Stock Quote</a
                              >
                            </li>
                            <li
                              data-v-1f5ec8f0=""
                              class="
                                menu-item
                                menubox2
                                menu-item-type-post_type
                                menu-item-object-page menu-item-88
                              "
                            >
                              <a
                                data-v-1f5ec8f0=""
                                :href="$rootUrl + 'IR_Contacts'"
                                class=""
                              >
                                IR Contacts</a
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <!-- <ul class="nav navbar-nav" id="menu-topnav">
                        <li
                          v-for="(item, index) in navList"
                          :key="index"
                          style="text-align: center"
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page
                            menu-item-has-children
                            menu-item-35
                            dropdown
                            dropLi
                          "
                        >
                          <router-link
                            v-if="item.children == null"
                            :to="item.url"
                            >{{ item.name }}</router-link
                          >
                          <template v-else>
                            <a
                              href="javaScript:"
                              class="dropdown-toggle dropChlid"
                              aria-haspopup="true"
                              @click.stop="aa(index)"
                              >{{ item.name }} <span class="caret"></span
                            ></a>
                            <ul
                              role="menu"
                              class="dropdown-menu dropList"
                              :class="[
                                navList[index].state == true ? 'show in' : '',
                              ]"
                              :style="
                                navList[index].state == true
                                  ? 'display:block'
                                  : 'display:none'
                              "
                            >
                              <li
                                v-for="(item2, index2) in item.children"
                                :key="index2"
                                class="
                                  menu-item
                                  menubox2
                                  menu-item-type-post_type
                                  menu-item-object-page menu-item-88
                                "
                              >
                                <router-link
                                  :to="{
                                    path: item2.url,
                                    query: {
                                      id: item2.code,
                                      title: item2.component,
                                      tableTitle: item2.pageTitle,
                                    },
                                  }"
                                  v-if="
                                    item2.children == null &&
                                    item2.code !== '' &&
                                    item2.jumpType !== 3
                                  "
                                >
                                  {{ item2.name }}</router-link
                                >

                                <a
                                  v-else-if="
                                    item2.children == null &&
                                    item2.jumpType == 3
                                  "
                                  :href="item2.redirect"
                                  target="view_window"
                                >
                                  {{ item2.name }}</a
                                >
                                <router-link
                                  :to="item2.url"
                                  v-else-if="
                                    item2.children == null && item2.code == ''
                                  "
                                >
                                  {{ item2.name }}</router-link
                                >
                                <template v-else>
                                  <a
                                    href="javascript:;"
                                    v-on:click.stop="threeNav($event)"
                                    >{{ item2.name }} <span class="caret"></span
                                  ></a>
                                  <ul class="dropdown-menu2">
                                    <li
                                      v-for="(item3, index3) in item2.children"
                                      :key="index3"
                                    >
                                      <router-link :to="item3.url">{{
                                        item3.name
                                      }}</router-link>
                                    </li>
                                  </ul>
                                </template>
                              </li>
                            </ul>
                          </template>阿
                        </li>
                      </ul> -->
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
// import "/public/home/js/bootstrap.min.js";
import { topMenu } from "../../util/api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      // 导航栏数组
      navList: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 初始化菜单栏
    // async init() {
    //   const { data: res } = await this.$http.get(topMenu);
    //   this.navList = res.result;
    //   // console.log(res)
    //   console.log(this.navList);
    // },
    // // 刷新回到顶部
    // scrollTop() {
    //   document.body.scrollTop = document.documentElement.scrollTop = 0;
    // },
    // // 二级菜单
    // aa(index) {
    //   var _this = this;
    //   var result = this.navList[index].state; /* 存储结果 */
    //   this.navList.forEach(function (item, index) {
    //     // console.log(item)
    //     if (item.children !== null) {
    //       item.state = false;
    //     }
    //   });
    //   // _this.navList[1].state = false;
    //   // _this.navList[3].state = false;
    //   // _this.navList[4].state = false;
    //   // _this.navList[5].state = false;
    //   // _this.navList[6].state = false;
    //   var data = this.navList;
    //   data[index].state = !result;
    //   _this.navList = [...data];
    //   if (data[index].state == true) {
    //     document.addEventListener("click", function () {
    //       btnClick();
    //     });
    //     // 按钮点击方法
    //     function btnClick() {
    //       //如果可见就为documnet对象绑定个一次性的单击事件
    //       _this.navList.forEach(function (item, index) {
    //         // console.log(item)
    //         if (item.children !== null) {
    //           item.state = false;
    //         }
    //       });
    //       // _this.navList[1].state = false;
    //       // _this.navList[3].state = false;
    //       // _this.navList[4].state = false;
    //       // _this.navList[5].state = false;
    //       // _this.navList[6].state = false;
    //       _this.navList.push("564");
    //       _this.navList.pop();
    //       // 删除事件绑定
    //       document.removeEventListener("click", btnClick, false);
    //     }
    //   }
    // },
    // // 三级菜单
    // threeNav(e) {
    //   e.stopPropagation();
    //   let target = e.target;
    //   $(target).siblings("ul").slideToggle();
    // },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.init();
    // this.scrollTop();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // (function ($) {
    // $(document).on("scroll", function () {
    //   if ($(document).scrollTop() > 50) {
    //     $(".sticky_header_container").addClass("shrink");
    //     $(".content_container").addClass("shrink");
    //   } else {
    //     $(".sticky_header_container").removeClass("shrink");
    //     $(".content_container").removeClass("shrink");
    //   }
    // });
    // /* 下拉菜单 */
    // $(".navbar-toggle").on("click", function () {
    //   $("#menu-topnav").slideToggle();
    // });
    // /* 监听屏幕宽度防止导航条消失 */
    // $(window).resize(function () {
    //   //  监控窗口宽度变化
    //   if ($(window).width() < 768) {
    //   } else {
    //     $(".menu-topnav").show();
    //   }
    // });
    // $("body").on("click", function () {
    //   console.log($(this).hasClass("menu-item"));
    //   if (!$(this).hasClass("dropdown-toggle")) {
    //   }
    // });
    // })(jQuery);
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
@import url("./../../../public/home/adagene.css");
@import url("./../../../public/home/style.css");
@import url("./../../../public/home/style.min.css");
@import url("./../../../public/home/css/bootstrap.css");
@import url("./../../../public/home/index.css");
@import url("./../../../public/home/js_composer_front_custom.css");
.header {
  box-shadow: none;
}
.navbar.navbar-default {
  top: 0;
}
body .navbar-nav {
  /* margin: 0; */
  padding: 0;
}
.menu-item {
  position: relative;
}
.menu-item .dropdown-menu2 {
  display: none;
  position: absolute;
  left: 190px;
  width: 200px;
  text-align: left;
  padding: 10px 30px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.9);
}
.menu-item .dropdown-menu2 a {
  color: #333;
  text-decoration: none;
  width: 100%;
  display: block;
}
.menu-item .dropdown-menu2 a:hover {
  color: #cc6633;
}
@media screen and (min-width: 768px) {
  #menu-topnav .dropLi:hover .dropList {
    display: block !important;
  }
  #menu-topnav .caret {
    display: block;
    position: absolute;
    right: 4px;
    top: 14px;
  }

  #menu-topnav {
    display: flex;
  }
  .menu-item {
    border-bottom: none;
  }
  #menu-topnav .dropdown-menu > li {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .home * {
    overflow: hidden;
  }
  a:focus {
    color: #fff;
  }
  .menu-item .dropdown-menu2 {
    position: initial;
    background: #096f95;
    width: 100%;
  }

  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus {
    background-color: #096f95 !important;
  }

  .dropdown-menu2 li a {
    padding-left: 30px;
    color: #fff;
  }
  /* =============== */
  .dropdown-menu,
  .dropdown-menu,
  .dropdown-menu {
    padding-left: 25px;
    display: none;
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .dropdown-menu li,
  .dropdown-menu li,
  .dropdown-menu li {
    border-bottom: 0;
  }
  .dropdown-menu li a,
  .dropdown-menu li a,
  .dropdown-menu li a {
    color: #fff;
  }
  .dropdown-menu li a:active,
  .dropdown-menu li a:active,
  .dropdown-menu li a:active {
    color: #fff;
  }
  .dropdown-menu li a:focus,
  .dropdown-menu li a:focus,
  .dropdown-menu li a:focus {
    color: #fff;
  }
  .toplist {
    padding: 0;
  }
  #menu-topnav {
    display: none;
  }
  #topmenulist {
    margin-left: 0;
    margin-right: 0;
  }
  #topmenulist .container {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
  #topmenulist .container > .row:nth-child(1) {
    display: none;
  }
  .menu-item .dropdown-menu2 a {
    color: #fff;
    text-decoration: none;
    width: 100%;
    display: block;
  }
}
</style>
