var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home page-template page-template-template-home page-template-template-home-php page page-id-11 wpb-js-composer js-comp-ver-6.4.2 vc_responsive"},[_c('Headers'),_c('div',{staticClass:"site-content",attrs:{"id":"content"}},[_c('div',{staticClass:"content-area",attrs:{"id":"primary"}},[_c('main',{staticClass:"site-main",attrs:{"id":"main","role":"main"}},[_c('div',{staticClass:"content_container homepage"},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12"},[_c('article',{staticClass:"post-11 page type-page status-publish hentry",attrs:{"id":"post-11"}},[_c('div',{staticClass:"entry-content"},[_c('div',{staticClass:"vc_row wpb_row vc_row-fluid mission_feature"},[_c('div',{staticClass:"wpb_column vc_column_container vc_col-sm-11 vc_col-sm-offset-1"},[_c('div',{staticClass:"vc_column-inner"},[_c('div',{staticClass:"wpb_wrapper"},[_c('div',{staticClass:"vc_row wpb_row vc_inner vc_row-fluid vc_row-o-equal-height vc_row-o-content-middle vc_row-flex"},[_vm._m(1),_c('div',{staticClass:"wpb_column vc_column_container vc_col-sm-5"},[_c('div',{staticClass:"vc_column-inner"},[_c('div',{staticClass:"wpb_wrapper"},[_c('div',{staticClass:"wpb_raw_code wpb_content_element wpb_raw_html"},[_c('div',{staticClass:"wpb_wrapper"},[_c('video',{attrs:{"width":"100%","height":"100%","poster":require('./../../public/home/img/indexImg2.png'),"controls":""}},[_c('source',{attrs:{"src":"https://11450065.s21v.faiusr.com/58/ABUIABA6GAAgk_CH7AUovJqGhAY.mp4","type":"video/mp4"}}),_vm._v(" 您的浏览器不支持 video 标签。 ")])])])])])])])])])])]),_c('div',{staticClass:"vc_row wpb_row vc_row-fluid discovery_engine_feature vc_custom_1601761030279 vc_row-has-fill",style:({
                        width: _vm.banner.width + 'px',
                        left: '-' + _vm.banner.left + 'px',
                        paddingLeft: _vm.banner.left + 'px',
                        paddingRight: _vm.banner.left + 'px',
                        height: '300px',
                      }),attrs:{"data-vc-full-width":" true","data-vc-full-width-init":"true"}},[_vm._m(2)]),_c('div',{staticClass:"vc_row-full-width vc_clearfix"}),_c('div',{staticClass:"vc_row-full-width vc_clearfix"})])])])])])])])])]),_c('Footers')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_banner_container"},[_c('div',{staticClass:"section_banner"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-8 col-md-6 col-sm-offset-2"},[_c('div',{staticClass:"section_banner_content"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wpb_column vc_column_container vc_col-sm-7"},[_c('div',{staticClass:"vc_column-inner"},[_c('div',{staticClass:"wpb_wrapper"},[_c('div',{staticClass:"wpb_text_column wpb_content_element add_outdent"},[_c('div',{staticClass:"wpb_wrapper"},[_c('h2',[_vm._v("盈喜集团访美接受汉天卫视专访")])])]),_c('div',{staticClass:"wpb_text_column wpb_content_element intro"},[_c('div',{staticClass:"wpb_wrapper"},[_c('p',[_vm._v(" 盈喜集团于2000年进入纺织服装领域，历经近20年的发展，盈喜集团现已成为纺织服装行业内专注于产业链服务的综合性集团公司。2016年，集团成功登陆美国资本市场。2022年8月31日，集团正式转板至纳斯达克主板上市，股票交易代码：ATXG。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wpb_column vc_column_container vc_col-sm-10 vc_col-sm-offset-1"},[_c('div',{staticClass:"vc_column-inner"},[_c('div',{staticClass:"wpb_wrapper"},[_c('div',{staticClass:"vc_row wpb_row vc_inner vc_row-fluid vc_row-o-equal-height vc_row-o-content-middle vc_row-flex"},[_c('div',{staticClass:"wpb_column vc_column_container"},[_c('div',{staticClass:"vc_column-inner"},[_c('div',{staticClass:"wpb_wrapper"},[_c('div',{staticClass:"wpb_text_column wpb_content_element"},[_c('div',{staticClass:"wpb_wrapper"},[_c('h2',[_vm._v(" 升级，为纺织服装企业发展提供持续动力 ")]),_c('p',[_vm._v(" 21世纪已经进入了第二个十年发展周期的黄金阶段。新一轮国际环境下，无论是资本市场还是实体经济，都经历着风云变幻的年代。而中国，也正在进入从需求侧向供给侧过渡调整的重要阶段。“去产能、降成本、补短板、集中区域资源优势、产业结构调整”等方针政策正在为中国经济体制改革指引方向。面对国家经济发展规划，如何优化区域产业结构、如何提升区域企业生产效率及实现有效供给已成为区域行业及企业面临的重要课题。 ")])])])])])])])])])])
}]

export { render, staticRenderFns }