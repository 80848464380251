import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import $ from 'jquery'
import './plugins/element.js'
import axios from 'axios'

// 配置请求的根路径
axios.defaults.baseURL = 'https://back.addentax.com/'
// axios.defaults.baseURL = 'http://192.168.0.106:8080/'

Vue.prototype.$http = axios
// Vue.prototype.$imgurl = 'http://image.fabbers.cn/'
Vue.prototype.$imgurl = 'https://upload.addentax.com/'
Vue.prototype.$rootUrl = 'https://www.addentax.com/'

// Vue.prototype.$fileurl = 'http://upload.fabbers.cn/'
Vue.prototype.$fileurl = 'https://upload.addentax.com/'
Vue.config.productionTip = false

// 使用 vue-meta
import Meta from "vue-meta";
Vue.use(Meta);

new Vue({
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')
