var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-container"},[_c('footer',{staticClass:"ng-scope",attrs:{"ng-include":"'footer.html'"}},[_c('div',{staticClass:"container ng-scope"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-8 col-sm-6 col-md-offset-1"},[_c('h2',{staticClass:"footer-title"},[_vm._v("网站地图")]),_c('div',{staticClass:"map-content row"},[_vm._l((_vm.footerList),function(item1,index1){return _c('div',{key:index1,staticClass:"col-xs-3 col-md-2",style:({ display: item1.bottom == true ? '' : 'none' })},[_c('dl',{staticClass:"f_l nav navbar-nav"},[_c('dt',[_vm._v(_vm._s(item1.title))]),_vm._l((item1.children),function(item2,index2){return _c('dd',{key:index2},[(item2.bottom == true)?[(item2.jumpType !== 3)?[_c('router-link',{attrs:{"to":{
                          path: item2.url,
                          query: {
                            id: item2.code,
                            title: item2.component,
                            tableTitle: item2.pageTitle,
                          },
                        }}},[_vm._v(_vm._s(item2.title))])]:[_c('a',{attrs:{"href":item2.redirect,"target":"view_window"}},[_vm._v(_vm._s(item2.title))])]]:_vm._e()],2)})],2)])}),_vm._m(1)],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 col-sm-6"},[_c('h2',{staticClass:"footer-title"},[_vm._v("集团介绍")]),_c('div',[_c('p',[_vm._v(" 盈喜集团于2000年进入纺织服装领域，历经20年的发展，盈喜集团现已成为纺织服装行业内专注于产业链服务的综合性集团公司，业务范围遍及全球。2016年，盈喜集团通过反向并购的方式，成功登陆美国资本市场。作为中国纺织服装行业产业链的探索者、实践者和引领着，盈喜集团秉承资源配置最优化、资源效益最大化的基本思想，从供给侧为全球纺织服装企业提供专业化、系统化服务，促进纺织服装企业改革并转型升级，为纺织服装企业发展提供持续动力。 ")]),_c('p',{staticClass:"copyright-text"},[_vm._v(" © 2021 "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/"}},[_vm._v("Addentax ATXG (粤ICP备20062687号)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12 col-md-4"},[_c('dl',{staticClass:"f_l nav navbar-nav"},[_c('dt',[_vm._v("官方微信")]),_c('dd',[_c('img',{staticClass:"qrCode",attrs:{"src":require("../../../public/components/qrCode.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }