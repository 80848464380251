<!--  -->
<template>
  <div class="footer-container">
    <footer ng-include="'footer.html'" class="ng-scope">
      <div class="container ng-scope">
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <h2 class="footer-title">集团介绍</h2>
            <div>
              <p>
                盈喜集团于2000年进入纺织服装领域，历经20年的发展，盈喜集团现已成为纺织服装行业内专注于产业链服务的综合性集团公司，业务范围遍及全球。2016年，盈喜集团通过反向并购的方式，成功登陆美国资本市场。作为中国纺织服装行业产业链的探索者、实践者和引领着，盈喜集团秉承资源配置最优化、资源效益最大化的基本思想，从供给侧为全球纺织服装企业提供专业化、系统化服务，促进纺织服装企业改革并转型升级，为纺织服装企业发展提供持续动力。
              </p>
              <p class="copyright-text">
                © 2021
                <a href="https://beian.miit.gov.cn/"
                  >Addentax ATXG (粤ICP备20062687号)</a
                >
              </p>
            </div>
          </div>
          <div class="col-md-8 col-sm-6 col-md-offset-1">
            <h2 class="footer-title">网站地图</h2>
            <div class="map-content row">
              <div
                class="col-xs-3 col-md-2"
                :style="{ display: item1.bottom == true ? '' : 'none' }"
                v-for="(item1, index1) in footerList"
                :key="index1"
              >
                <dl class="f_l nav navbar-nav">
                  <dt>{{ item1.title }}</dt>
                  <dd v-for="(item2, index2) in item1.children" :key="index2">
                    <template v-if="item2.bottom == true">
                      <!-- {{ item2 }} -->
                      <template v-if="item2.jumpType !== 3">
                        <router-link
                          :to="{
                            path: item2.url,
                            query: {
                              id: item2.code,
                              title: item2.component,
                              tableTitle: item2.pageTitle,
                            },
                          }"
                          >{{ item2.title }}</router-link
                        >
                      </template>
                      <template v-else>
                        <a :href="item2.redirect" target="view_window">{{
                          item2.title
                        }}</a>
                      </template>
                    </template>
                  </dd>
                </dl>
              </div>
              <!-- <div class="col-xs-3 col-md-2">
                <dl class="f_l nav navbar-nav">
                  <dt>关于我们</dt>
                  <dd>
                    <router-link to="/synopsis">集团简介</router-link>
                  </dd>
                  <dd>
                    <router-link to="/address">联系我们</router-link>
                  </dd>
                </dl>
              </div>
              <div class="col-xs-3 col-md-2">
                <dl class="f_l nav navbar-nav">
                  <dt>新闻中心</dt>
                  <dd>
                    <router-link to="/newcontent">新闻发布</router-link>
                  </dd>
                </dl>
              </div>
              <div class="col-xs-3 col-md-2">
                <dl class="f_l nav navbar-nav">
                  <dt>投资者关系</dt>
                  <dd><a href="activity.html">收益发布</a></dd>
                  <dd><a href="activity.html">财务报表</a></dd>
                  <dd>
                    <a
                      href="https://www.otcmarkets.com/stock/ATXG/overview"
                      target="view_window"
                      >股价图</a
                    >
                  </dd>
                </dl>
              </div>
              <div class="col-xs-3 col-md-2">
                <dl class="f_l nav navbar-nav">
                  <dt>集团子公司</dt>
                  <dd>
                    <router-link to="/subsidiary?id=daying"
                      >东莞市大莹服装批发有限公司</router-link
                    >
                  </dd>
                  <dd>
                    <router-link to="/subsidiary?id=huapengfa"
                      >深圳市盈喜鹏发物流有限公司</router-link
                    >
                  </dd>
                  <dd>
                    <router-link to="/subsidiary?id=xinkuaijie"
                      >深圳市新快捷运输有限公司</router-link
                    >
                  </dd>
                  <dd>
                    <router-link to="/subsidiary?id=daitou"
                      >汕头市佰伊服饰有限公司</router-link
                    >
                  </dd>
                  <dd>
                    <router-link to="/subsidiary?id=hengsheng"
                      >东莞市恒圣威服装有限公司</router-link
                    >
                  </dd>
                  <dd>
                    <router-link to="/subsidiary?id=yushang"
                      >东莞誉尚服饰有限公司</router-link
                    >
                  </dd>
                </dl>
              </div> -->
              <div class="col-xs-12 col-md-4">
                <dl class="f_l nav navbar-nav">
                  <dt>官方微信</dt>
                  <dd>
                    <img
                      class="qrCode"
                      src="../../../public/components/qrCode.png"
                      alt=""
                    />
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import "/public/home/js/bootstrap.min.js";
import footList from './data.json'
import { topMenu } from "../../util/api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      // 底部菜单数组
      footerList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async init() {
      // const { data: res } = await this.$http.get(topMenu);
      // console.log(res);
      // this.footerList = res.result;

      this.footerList = footList

      // let arrList = []
      // let arr1 = []
      // res.result.forEach(function (item1, index1) {
      //   // console.log(item1.bottom)
      //   if (item1.bottom == true && item1.children !== null) {
      //     arr1.push(item1)
      //     let arr2 = [];
      //     // console.log(arr1)
      //     item1.children.forEach(function (item2, index2) {
      //       if (item2.bottom == true) {
      //           // console.log(index2,item1.title)
      //         arr2.push(item2);
      //         // arrList[index2] = {
      //         //   dt: item1.title,
      //         // }
      //         // console.log(arr2)
      //       }
      //     });
      //   }
      // });
      // console.log(arr2)
      // this.footerList = arrList;
      console.log(this.footerList);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="less" scoped>
@import url("./../../../public/home/adagene.css");
@import url("./../../../public/home/style.css");
@import url("./../../../public/home/style.min.css");
@import url("./../../../public/home/css/bootstrap.css");
@import url("./../../../public/home/index.css");
@import url("./../../../public/home/js_composer_front_custom.css");
@import url("./../../../public/components/footer.less");
</style>
