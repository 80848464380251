import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css';
import {
  Pagination,
  Table,
  TableColumn,
  Collapse,
  CollapseItem,
} from 'element-ui'

Vue.use(Pagination)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Collapse)
Vue.use(CollapseItem)