<!--  -->
<template>
  <div
    class="
      home
      page-template
      page-template-template-home
      page-template-template-home-php
      page
      page-id-11
      wpb-js-composer
      js-comp-ver-6.4.2
      vc_responsive
    "
  >
    <Headers></Headers>
    <!-- <AA></AA> -->
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main" role="main">
          <div class="content_container homepage">
            <div class="section_banner_container">
              <div class="section_banner">
                <!-- <div
                  class="overlay"
                  style="
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    content: '';
                    background: rgba(0, 0, 0, 0.3);
                  "
                ></div> -->
                <div class="container">
                  <div class="row">
                    <div class="col-xs-12 col-sm-8 col-md-6 col-sm-offset-2">
                      <div class="section_banner_content">
                        <!-- <h1>
                          <strong>专注于</strong> 纺织服装行业<br /><br />
                          产业链服务
                        </h1> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container">
              <div class="row">
                <div class="col-xs-12">
                  <article
                    id="post-11"
                    class="post-11 page type-page status-publish hentry"
                  >
                    <div class="entry-content">
                      <div class="vc_row wpb_row vc_row-fluid mission_feature">
                        <div
                          class="
                            wpb_column
                            vc_column_container vc_col-sm-11 vc_col-sm-offset-1
                          "
                        >
                          <div class="vc_column-inner">
                            <div class="wpb_wrapper">
                              <div
                                class="
                                  vc_row
                                  wpb_row
                                  vc_inner
                                  vc_row-fluid
                                  vc_row-o-equal-height
                                  vc_row-o-content-middle
                                  vc_row-flex
                                "
                              >
                                <div
                                  class="
                                    wpb_column
                                    vc_column_container vc_col-sm-7
                                  "
                                >
                                  <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                      <div
                                        class="
                                          wpb_text_column wpb_content_element
                                          add_outdent
                                        "
                                      >
                                        <div class="wpb_wrapper">
                                          <h2>盈喜集团访美接受汉天卫视专访</h2>
                                        </div>
                                      </div>

                                      <div
                                        class="
                                          wpb_text_column wpb_content_element
                                          intro
                                        "
                                      >
                                        <div class="wpb_wrapper">
                                          <p>
                                            盈喜集团于2000年进入纺织服装领域，历经近20年的发展，盈喜集团现已成为纺织服装行业内专注于产业链服务的综合性集团公司。2016年，集团成功登陆美国资本市场。2022年8月31日，集团正式转板至纳斯达克主板上市，股票交易代码：ATXG。
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="
                                    wpb_column
                                    vc_column_container vc_col-sm-5
                                  "
                                >
                                  <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                      <div
                                        class="
                                          wpb_raw_code
                                          wpb_content_element
                                          wpb_raw_html
                                        "
                                      >
                                        <div class="wpb_wrapper">
                                          <video
                                            width="100%"
                                            height="100%"
                                            :poster="
                                              require('./../../public/home/img/indexImg2.png')
                                            "
                                            controls
                                          >
                                            <source
                                              src="https://11450065.s21v.faiusr.com/58/ABUIABA6GAAgk_CH7AUovJqGhAY.mp4"
                                              type="video/mp4"
                                            />
                                            您的浏览器不支持 video 标签。
                                          </video>
                                          <!-- <img
                                            :src="
                                              require('./../../public/home/img/indexImg2.png')
                                            "
                                            alt=""
                                          /> -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        data-vc-full-width=" true"
                        data-vc-full-width-init="true"
                        :style="{
                          width: banner.width + 'px',
                          left: '-' + banner.left + 'px',
                          paddingLeft: banner.left + 'px',
                          paddingRight: banner.left + 'px',
                          height: '300px',
                        }"
                        class="
                          vc_row
                          wpb_row
                          vc_row-fluid
                          discovery_engine_feature
                          vc_custom_1601761030279 vc_row-has-fill
                        "
                      >
                        <!-- <div class="overlay"
                          style="position: absolute;top: 0;bottom: 0;left: 0;right: 0;content: '';background: rgba(0, 0, 0, 0.3);">
                        </div> -->
                        <div
                          class="
                            wpb_column
                            vc_column_container vc_col-sm-10 vc_col-sm-offset-1
                          "
                        >
                          <div class="vc_column-inner">
                            <div class="wpb_wrapper">
                              <div
                                class="
                                  vc_row
                                  wpb_row
                                  vc_inner
                                  vc_row-fluid
                                  vc_row-o-equal-height
                                  vc_row-o-content-middle
                                  vc_row-flex
                                "
                              >
                                <div class="wpb_column vc_column_container">
                                  <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                      <div
                                        class="
                                          wpb_text_column wpb_content_element
                                        "
                                      >
                                        <div class="wpb_wrapper">
                                          <h2>
                                            升级，为纺织服装企业发展提供持续动力
                                          </h2>
                                          <p>
                                            21世纪已经进入了第二个十年发展周期的黄金阶段。新一轮国际环境下，无论是资本市场还是实体经济，都经历着风云变幻的年代。而中国，也正在进入从需求侧向供给侧过渡调整的重要阶段。“去产能、降成本、补短板、集中区域资源优势、产业结构调整”等方针政策正在为中国经济体制改革指引方向。面对国家经济发展规划，如何优化区域产业结构、如何提升区域企业生产效率及实现有效供给已成为区域行业及企业面临的重要课题。
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vc_row-full-width vc_clearfix"></div>

                      <div class="vc_row-full-width vc_clearfix"></div>
                    </div>
                    <!-- .entry-content -->
                  </article>
                  <!-- #post-633 -->
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- #content -->

    <Footers></Footers>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Headers from "./../components/header/header.vue";
import Footers from "./../components/footer/footer.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Headers, Footers },
  data() {
    //这里存放数据
    return {
      banner: {
        width: 0,
        left: 0,
      },
      screenWidth: document.body.clientWidth,
    };
  },
  metaInfo: {
    title: "盈喜集团 - 致力打造中国领先的纺织服装产业链服务平台",
    meta: [
      {
        name: "keywords",
        content:
          "盈喜集团，盈喜产业链，盈喜纺织服装，盈喜股份，盈喜集团官网，ATXG",
      },
      {
        name: "description",
        content:
          "盈喜集团于2000年进入纺织服装领域，历经近20年的发展，现已成为纺织服装行业内专注于产业链服务的综合性集团公司。2016年，集团成功登陆美国资本市场。作为中国纺织服装行业产业链的探索者、实践者和引领者，从供给侧为全球纺织服装企业提供专业化、系统化产业链服务。",
      },
    ],
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 根据屏幕宽度改变图片大小
    initBanner() {
      let w = window.innerWidth;
      if (w > 750) {
        w = w - 15;
      } else {
        w = w;
      }
      let delW = 0;
      if (w >= 1170) {
        delW = 1170;
      } else if (750 <= w && w <= 1170) {
        delW = 970;
      } else {
        delW = 750;
      }
      this.banner.width = w;
      this.banner.left = (w - delW) / 2;
      console.log(this.banner);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.initBanner();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.screenWidth);
          that.initBanner();
          that.timer = false;
        }, 400);
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
// @import url(''); //引入公共css类
.wpb_wrapper {
  line-height: 35px;
  h2 {
    font-weight: 700;
  }
  p {
    text-indent: 2em;
  }
}
.content_container {
  min-height: 800px;
  padding: 169px 0 0px;
  transition: padding-top 0.3s ease;
}
</style>