import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  // redirect: '/home',
  component: Home /* 首页 */
},
{
  path: '/home',
  name: 'Home',
  component: Home /* 首页 */
},
// {
//   // path: '/newlist/:id/:title/:tableTitle',
//   path: '/newlist',
//   name: 'NewList',
//   component: function () {
//     return import( /* 新闻发布 */ '../views/NewList.vue')
//   },
// },
{
  path: '/investor',
  name: 'investor',
  redirect: '/investor/p1',
  component: function () {
    return import( /* 投资者保护外部框架 */ '../views/Investor.vue')
  },
  children: [{
    path: 'p1',
    name: 'p1',
    component: function () {
      return import( /* 投资者保护宣传切换内容子路由 */ '../components/investor/p1.vue')
    }
  }]
},
{
  path: '/page2',
  name: 'page2',
  component: function () {
    return import( /* webpackChunkName: "about" */ '../views/Page2.vue')
  }
},
{
  path: '/page3',
  name: 'page3',
  component: function () {
    return import( /* webpackChunkName: "about" */ '../views/Page3.vue')
  }
},
{
  path: '/synopsis',
  name: 'synopsis',
  component: function () {
    return import( /* 公司简介 */ '../views/group/Synopsis.vue')
  }
},
{
  path: '/newcontent',
  name: 'newcontent',
  component: function () {
    return import( /* 新闻详情页 */ '../views/NewContent.vue')
  }
},
{
  path: '/address',
  name: 'address',
  component: function () {
    return import( /* 盈喜地址 */ '../views/Address.vue')
  }
},
{
  path: '/culture',
  name: 'culture',
  component: function () {
    return import( /* 企业文化 */ '../views/group/Culture.vue')
  }
},
// {
//   path: '/directors',
//   name: 'directors',
//   component: function () {
//     return import( /* 董事会 */ '../views/investor/Directors.vue')
//   }
// },
{
  path: '/directors',
  name: 'directors',
  component: function () {
    return import( /* 董事会 */ '../views/IR/directors.vue')
  }
},
// {
//   path: '/boardStructure',
//   name: 'boardStructure',
//   component: function () {
//     return import( /* 董事会结构*/ '../views/investor/BoardStructure.vue')
//   }
// },
{
  path: '/boardStructure',
  name: 'boardStructure',
  component: function () {
    return import( /* 董事会结构*/ '../views/IR/boardStructure.vue')
  }
},

{
  path: '/subsidiary',
  name: 'subsidiary',
  component: function () {
    return import( /* 子公司 */ '../views/subsidiary/Subsidiary.vue')
  }
},
/***********子公司 start */
{
  path: '/daying',
  name: 'daying',
  component: function () {
    return import( /* 大莹 */ '../views/company/daying.vue')
  }
},
{
  path: '/huapengfa',
  name: 'huapengfa',
  component: function () {
    return import( /* 华鹏发 */ '../views/company/huapengfa.vue')
  }
},
{
  path: '/xinkuaijie',
  name: 'xinkuaijie',
  component: function () {
    return import( /* 快捷 */ '../views/company/xinkuaijie.vue')
  }
},
{
  path: '/daitou',
  name: 'daitou',
  component: function () {
    return import( /* 埭头 */ '../views/company/daitou.vue')
  }
},
{
  path: '/hengsheng',
  name: 'hengsheng',
  component: function () {
    return import( /* 恒圣 */ '../views/company/hengsheng.vue')
  }
},
{
  path: '/yushang',
  name: 'yushang',
  component: function () {
    return import( /* 誉尚 */ '../views/company/yushang.vue')
  }
},
/***********子公司 end */

{
  path: '/journaling',
  name: 'journaling',
  component: function () {
    return import( /* 子公司 */ '../views/Journaling.vue')
  }
},

/****************投资者关系**********************start */

{
  path: '/government',
  name: 'government',
  component: function () {
    return import( /* 公司治理文件 */ '../views/IR/government.vue')
  }
},
{
  path: '/regular',
  name: 'regular',
  component: function () {
    return import( /* 定期报告 */ '../views/IR/regular.vue')
  }
},
{
  path: '/quarter',
  name: 'quarter',
  component: function () {
    return import( /* 季报 */ '../views/IR/quarter.vue')
  }
},
{
  path: '/year',
  name: 'year',
  component: function () {
    return import( /* 年报 */ '../views/IR/year.vue')
  }
},
{
  path: '/demonstration',
  name: 'demonstration',
  component: function () {
    return import( /* 投资者文稿 */ '../views/IR/demonstration.vue')
  }
},
{
  path: '/corporate_profile',
  name: 'corporate_profile',
  component: function () {
    return import( /* 投资者文稿 */ '../views/IR/corporate_profile.vue')
  }
},
{
  path: '/directors_en',
  name: 'directors_en',
  component: function () {
    return import( /* 投资者文稿 */ '../views/IR/directors_en.vue')
  }
},
{
  path: '/composition',
  name: 'composition',
  component: function () {
    return import( /* 投资者文稿 */ '../views/IR/composition.vue')
  }
},
{
  path: '/IR_Contacts',
  name: 'IR_Contacts',
  component: function () {
    return import( /* 投资者文稿 */ '../views/IR/IR_Contacts.vue')
  }
},
/***************投资者关系***********************end */

{
  path: '/recruit',
  name: 'recruit',
  component: function () {
    return import( /* 盈喜找平 */ '../views/recruit.vue')
  }
},

{
  path: '/administration',
  name: 'administration',
  component: function () {
    return import( /* 管理架构 */ '../views/administration.vue')
  }
},
{
  path: '/operate',
  name: 'operate',
  component: function () {
    return import( /* 运营架构 */ '../views/operate.vue')
  }
},
{
  path: '/pattern',
  name: 'pattern',
  component: function () {
    return import( /* 集团模式 */ '../views/pattern.vue')
  }
},
{
  path: '/spin',
  name: 'spin',
  component: function () {
    return import( /* 纺织 */ '../views/spin.vue')
  }
},
{
  path: '/logistics',
  name: 'logistics',
  component: function () {
    return import( /* 物流 */ '../views/logistics.vue')
  }
},
{
  path: '/antiepidemic',
  name: 'antiepidemic',
  component: function () {
    return import( /* 防疫 */ '../views/antiepidemic.vue')
  }
},
{
  path: '/page',
  name: 'page',
  component: function () {
    return import( /* 子公司 */ '../views/Page.vue')
  }
},
{
  path: '/list',
  name: 'newsList',
  component: function () {
    return import( /* 新闻发布 */ '../views/NewList.vue')
  }
},
/**********************start */
{
  path: '/qyxw',
  name: 'qyxw',
  component: function () {
    return import( /* 行业动态 */ '../views/news/qyxw.vue')
  }
},
{
  path: '/hydt',
  name: 'hydt',
  component: function () {
    return import( /* 行业动态 */ '../views/news/hydt.vue')
  }
},
{
  path: '/eng_news',
  name: 'eng',
  component: function () {
    return import( /* 行业动态 */ '../views/news/eng.vue')
  }
}

  /**********************end */

]

const router = new VueRouter({
  mode: 'history',
  /*将默认的history模式更改为hash模式*/
  base: process.env.BASE_URL,
  routes
})

export default router